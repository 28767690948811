/**
 * Validation rule
 * @param {string} fieldName
 * @param {boolean} isRequired
 */
export const alphaAndSpaceRule = (fieldName, isRequired = true) => {
  const rules = [];
  if (isRequired) {
    rules.push({
      required: true,
      message: `${fieldName ? fieldName : 'This field'} is required`,
      trigger: 'blur'
    });
  }
  rules.push({
    pattern: /^[a-zA-Z]([a-zA-Z\s]?)+$/,
    message: 'special characters are not allowed',
    trigger: 'blur'
  });
  return rules;
};
